<template>
	<b-card class="variable-card">
		<div class="variable-title">
			<h3>{{ variable.name }}</h3>
			<h5>{{ variable.description }}</h5>
		</div>
		<div class="variable-content">
			<ul>
				<li><b>{{ $t('variable.type') }}</b> : {{ variable.type }}</li>
				<li><b>{{ $t('variable.valueType') }}</b> : {{ variable.valueType }}</li>
			</ul>
		</div>
		<div class="variable-buttons">
			<ul>
				<li  v-if="getCurrentUserId() === variable.createdBy">
					<b-button v-b-tooltip.hover.bottom="$t('variable.edit')" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-warning" class="rounded-circle" @click="handleEditVariable(variable, true)">
						<div>
							<feather-icon size="18" icon="EditIcon"/>
						</div>
					</b-button>
				</li>
				<li v-else-if="variable.public && variable.options !== null">
					<b-button v-b-tooltip.hover.bottom="$t('variable.view')" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" class="rounded-circle" @click="handleEditVariable(variable, false)">
						<div>
							<feather-icon size="18" icon="EyeIcon"/>
						</div>
					</b-button>
				</li>
				<li v-if="getCurrentUserId() === variable.createdBy">
					<b-button v-b-tooltip.hover.bottom="$t('variable.delete')" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="rounded-circle" @click="handleDeleteVariable(variable.id)">
						<div>
							<feather-icon size="18" icon="TrashIcon"/>
						</div>
					</b-button>
				</li>
			</ul>
		</div>
	</b-card>
</template>

<script>
import {BButton, BCard} from "bootstrap-vue";

export default {
	props: {
		getCurrentUserId: {
			required: true,
		},
		variable: {
			type: Object,
			required: true
		},
		handleDeleteVariable: {
			required: true,
		},
		handleEditVariable: {
			required: true
		}
	},
	components: {
		BButton, BCard
	}
}
</script>

<style lang="scss">
	.variable-card {
		border-radius: 20px;
		border: none !important;
		box-shadow: 0px 7px 15px 0px rgba(#000, .1);
		min-height: 200px;
		.card-body {
			padding: 40px 80px 40px 40px;
		}
		.variable-title {
			margin-bottom: 25px;
			h3 {
				font-weight: bold;
				font-size: 17px;
				color: #7367f0;
			}
		}
		.variable-content {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					font-size: 15px;
					b {

					}
				}
			}
		}
		.variable-buttons {
			position: absolute;
			top: 30px;
			right: 20px;
			z-index: 1;
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}
		}
	}
</style>