<template>
  <div>
    <b-form @submit.prevent>
      <b-col>
        <br/>
        <b-form-input :disabled="true"
                      v-model="variable.name"
                      id="var-name"/>
        <br/>
        <b-form-input :disabled="!editMode"
                      v-model="variable.description"
                      id="var-description"
                      :placeholder="$t('variable.description')"/>
      </b-col>

      <div v-if="variable.type === `ORG_BANK` && variable.valueType === `SELECTION`">
        <b-col v-if="variable.children !== null">
          <br/>
          <template v-for="(lv,ix) in variable.children">
            <div :key="ix">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="6"></b-col>
                    <b-col cols="6">
                      <b-form-input :disabled="true"
                                    v-model="lv.variableName"
                                    id="var-name"
                                    :placeholder="$t('variable.subvarible')"
                                    v-no-space/>
                    </b-col>
                  </b-row>
                  <b-row class="mr-0 ml-0 mt-1 mb-1">
                    <b-form-input v-model="lv.description"
                                  id="var-description"
                                  :placeholder="$t('variable.description')"/>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </template>
        </b-col>

        <b-dropdown-divider/>
      </div>

      <b-col v-if="variable.type === `ORG_BANK` && variable.valueType === `SELECTION`">
        <br/>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col v-for="(linkedVar, i) in variable.children" :key="i">
                <b-row v-for="(option,ix) in linkedVar.options" :key="ix">
                  <b-form-input class="m-1"
                                v-model="option.option"
                                id="var-option"
                                :placeholder="linkedVar.variableName"/>
                </b-row>
              </b-col>
            </b-row>
          </b-col>

<!--          <b-col cols="2">-->
<!--            <b-row>-->
<!--              <b-col v-for="(linkedVar, i) in variable.children" :key="i">-->
<!--                <b-row v-for="(option,ix) in linkedVar.options" :key="ix">-->
<!--                  <b-button v-if="(i === 0) && editMode"-->
<!--                            v-b-tooltip.hover.bottom="$t('variable.removeOption')"-->
<!--                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"-->
<!--                            variant="flat-danger"-->
<!--                            class="rounded-circle m-1"-->
<!--                            @click="removeOptionFromLinkedVariable(option)">-->
<!--                    <div>-->
<!--                      <feather-icon size="18" icon="TrashIcon"/>-->
<!--                    </div>-->
<!--                  </b-button>-->
<!--                </b-row>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--          </b-col>-->
        </b-row>

        <b-button v-show="variable.type !== `ORG_BANK` && variable.valueType !== `SELECTION`"
                  v-if="editMode"
                  @click="addVariableValueOptionForLinkedVariable">
          {{ $t('variable.addOption') }}
        </b-button>

        <b-dropdown-divider/>
      </b-col>

      <b-col v-if="variable.children === null && variable.valueType === `SELECTION`">
        <br/>
        <template v-for="(option, ix) in variable.options">
          <b-row :key="ix" class="mt-1 mb-1">
            <b-col cols="">
              <b-form-input :disabled="!editMode"
                            v-model="option.option"
                            id="var-option"
                            :placeholder="$t('variable.variableValueOption')"/>
            </b-col>
            <b-col cols="2">
              <b-button
                  v-show="editMode"
                  v-b-tooltip.hover.bottom="$t('variable.removeOption')"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="rounded-circle ml-1 mr-1"
                  @click="removeOption(option)">
                <div class="icon-wrapper">
                  <feather-icon icon="TrashIcon"/>
                </div>
              </b-button>
            </b-col>
          </b-row>
        </template>
        <b-button v-if="editMode"  @click="addVariableValueOption">
         {{ $t('variable.addInput')}}
        </b-button>

        <b-dropdown-divider/>
      </b-col>

      <b-col>
        <br/>
        <div class="d-flex justify-content-between align-items-center">
          <label for="var-is-required"> {{ $t('variable.isRequired') }}</label>
          <b-form-checkbox :disabled="!editMode"
                           v-model="variable.required"
                           id="var-is-required"
                           switch/>
        </div>
      </b-col>
      <b-col>
        <br/>
        <div v-if="editMode" class="d-flex justify-content-between align-items-center">
          <label for="var-is-public"> {{ $t('variable.isPublic') }}</label>
          <b-form-checkbox v-model="variable.public"
                           id="var-is-public"
                           switch/>
        </div>
      </b-col>

      <b-col cols="12">
        <br/>
        <b-row align-h="end" class="px-1">
          <b-button v-show="variable.type !== `ORG_BANK`"
                    v-if="editMode"
                    @click="updateVariable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    :disabled="invalid"
                    variant="primary">
            {{ $t('buttons.save') }}
          </b-button>
        </b-row>
      </b-col>
    </b-form>
  </div>
</template>

<script>
import {BButton, BCol, BDropdownDivider, BForm, BFormCheckbox, BFormInput, BRow, VBTooltip} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {v4 as uuidv4} from 'uuid'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {PEventBus, VariableEventTypes} from "@/services/PEventBus";
import i18n from "@/libs/i18n";

export default {
  name: "UpdateVariable",
  components: {
    BRow, BCol, BButton, BForm, BFormInput, BFormCheckbox, BDropdownDivider
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    variable: {
      type: Object
    },
    editMode: {
      type: Boolean,
    }
  },
  data() {
    return {
      invalid: false,
    }
  },
  mounted() {
    PEventBus.$on(VariableEventTypes.VARIABLE_UPDATE_FAILED, () => this.showErrorToast())
  },
  beforeDestroy() {
    PEventBus.$off(VariableEventTypes.VARIABLE_UPDATE_FAILED, () => this.showErrorToast())
  },
  methods: {
    updateVariable() {
      const payload = {
        variableId: this.variable.id,
        request: {
          description: this.variable.description,
          options: this.variable.options,
          isRequired: this.variable.required,
          isPublic: this.variable.public
        }
      }

      this.$store.dispatch('variables/updateOne', payload)
    },
    addVariableValueOption() {
      this.variable.options.push({
        id: uuidv4(),
        option: null,
        optionId: null
      })
    },
    removeOption(option) {
      let removedElement = {}
      if (option.optionId !== null) {
        removedElement = this.variable.options.filter(o => o.optionId === option.optionId)[0]
      } else {
        removedElement = this.variable.options.filter(o => o.id === option.id)[0]
      }

      let removedIndex = this.variable.options.indexOf(removedElement)
      this.variable.options.splice(removedIndex, 1)
    },
    showErrorToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.variable.failedOnUpdate'),
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      }, {position: 'top-center'})
    },
    addVariableValueOptionForLinkedVariable() {
      this.variable.children.map(lv => {
        lv.options.push({
          id: uuidv4(),
          option: null
        })
      })
    },
    addLinkedVariable() {
      this.variable.children.push({
        tempId: uuidv4(),
        name: null,
        description: null,
        options: []
      })
    },
    removeLinkedVariable(ix) {
      this.variable.children.splice(ix, 1)
    },
    removeOptionFromLinkedVariable(option) {

      this.variable.children.map(lv => {

        let removedElement = {}
        if (option.optionId === null) {
          removedElement = lv.options.filter(o => o.optionId === option.optionId)[0]
        } else {
          removedElement = lv.options.filter(o => o.id === option.id)[0]
        }
        let removedIndex = lv.options.indexOf(removedElement)
        lv.options.splice(removedIndex, 1)
      })
    },
  }
}
</script>

<style scoped>

</style>