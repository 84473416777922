<template>
	<div>
		<loading v-if="loading"></loading>
		<div v-show="!loading">
			<b-modal scrollable ref="add-new-variable-modal" hide-footer no-close-on-backdrop size="lg" :title="$t('variable.add')">
				<add-new-variable></add-new-variable>
			</b-modal>
			<b-modal scrollable ref="update-variable-modal" hide-footer no-close-on-backdrop size="lg" :title="editMode ? $t('variable.edit') : $t('variable.view')">
				<update-variable :variable="updatedVariable" :edit-mode="editMode"/>
			</b-modal>
			<b-row>
				<b-col lg="4" md="6" sm="12">
					<b-card @click="addNewVariableModal" class="cursor-pointer var-card justify-content-around">
						<upload-icon size="28"/>
						<h4 class="add-new-card">{{ $t('variable.create') }}</h4>
					</b-card>
				</b-col>
				<b-col v-for="(variable,ix) in variables" :key="ix" lg="4" md="6" sm="12">
					<variable-card :variable="variable" :getCurrentUserId="getCurrentUserId" :handleDeleteVariable="handleDeleteVariable" :handleEditVariable="handleEditVariable"/>
				</b-col>
			</b-row>
		</div>
	</div>

</template>

<script>
import {mapState} from "vuex";
import VariableCard from "@/views/cards/variableCard";
import {BButton, BCard, BCardText, BCol, BRow, VBTooltip} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import AddNewVariable from "@/views/Variables/Create/AddNewVariable";
import {PEventBus, VariableEventTypes} from "@/services/PEventBus";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import UpdateVariable from "@/views/Variables/Update/UpdateVariable";
import Loading from "@/views/components/Loading/Loading";
import i18n from "@/libs/i18n";
import {UploadIcon} from 'vue-feather-icons'

export default {
	name: "VariableList",
	components: {
		UpdateVariable,
		AddNewVariable,
		Loading, VariableCard,
		BCard, BRow, BCol,
		UploadIcon
	},
	directives: {
		Ripple,
		'b-tooltip': VBTooltip,
	},

	data() {
		return {
			editMode: false,
			updatedVariable: {}
		}
	},
	computed: {
		...mapState({
			variables: state => state.variables.variableList,
			loading: state => state.variables.listLoading
		})
	},
	mounted() {
		this.fetchVariables()
		PEventBus.$on(VariableEventTypes.VARIABLE_CREATED, () => this.reloadVariables())
		PEventBus.$on(VariableEventTypes.VARIABLE_UPDATED, () => this.reloadVariables())
		PEventBus.$on(VariableEventTypes.VARIABLE_DELETED, () => this.reloadVariables())
	},
	beforeDestroy() {
		PEventBus.$off(VariableEventTypes.VARIABLE_CREATED, () => this.reloadVariables())
		PEventBus.$off(VariableEventTypes.VARIABLE_UPDATED, () => this.reloadVariables())
		PEventBus.$off(VariableEventTypes.VARIABLE_DELETED, () => this.reloadVariables())
	},
	methods: {
		fetchVariables() {
			this.$store.dispatch("variables/loadAll")
		},
		addNewVariableModal() {
			this.$refs['add-new-variable-modal'].show()
		},
		reloadVariables() {
			this.$refs['add-new-variable-modal'].hide()
			this.$refs['update-variable-modal'].hide()
			this.fetchVariables()
		},
		getCurrentUserId() {
			const user = JSON.parse(localStorage.getItem('userData'))
			return user.userId
		},
		handleEditVariable(variable, editable) {
			this.$refs['update-variable-modal'].show()
			this.editMode = editable
			this.updatedVariable = JSON.parse(JSON.stringify(variable));
		},
		handleDeleteVariable(id) {
			this.$bvModal
				.msgBoxConfirm(this.$i18n.t('messages.variable.questionBeforeDelete'), {
					title: i18n.t('messages.areYouSure'),
					size: 'sm',
					okVariant: 'danger',
					okTitle: i18n.t('yes'),
					cancelTitle: i18n.t('exit'),
					cancelVariant: 'outline-primary',
					hideHeaderClose: false,
					centered: false,
				})
				.then(value => {
					if (value) {
						let icon = 'TrashIcon'
						this.$store.dispatch('variables/deleteOne', id)
							.then(() => {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: i18n.t('messages.notification'),
										icon,
										text: i18n.t('messages.variable.deleted'),
										variant: 'danger',
									},
								}, {position: 'top-center'})
							})
					}
				})
		},
	}
}
</script>

<style lang="scss" scoped>
.var-card {
	border-radius: 20px;
	border: none !important;
	box-shadow: 0px 7px 15px 0px rgba(#000, .1);
	background: #7367f0;
	min-height: 200px;
	.card-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		svg {
			stroke: white;
			margin-bottom: 15px;
		}
		h4 {
			color: white;
		}
	}
	&:hover {
		transition: box-shadow 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
		box-shadow: 0 0 1px 1px #03A5AA, 0 0 1px 1px #03A5AA;
	}
}
</style>