<template>
  <div>
    <b-form @submit.prevent>
      <b-col>
        <b-form-input v-model="name"
                      id="var-name"
                      :placeholder="$t('variable.name')"
                      v-no-space/>
      </b-col>
      <b-col>
        <br/>
        <b-form-input v-model="description"
                      id="var-description"
                      :placeholder="$t('variable.description')"/>
      </b-col>

      <b-col>
        <br/>
        <b-overlay id="loading-document-type"
                   :show="false"
                   :variant="'light'"
                   :opacity="0.65"
                   rounded="sm">
          <b-form-group>
            <h6 class="section-label mb-1">
              {{ $t('variable.type') }}
            </h6>
            <v-select v-model="type"
                      :label="`name`"
                      :options="variableTypes"/>
            <h6 class="section-label mt-2">
              {{ type != null ? type.displayText : `` }}
            </h6>
          </b-form-group>
        </b-overlay>
      </b-col>
      <b-col>
        <br/>
        <b-overlay id="loading-document-type"
                   :show="false"
                   :variant="'light'"
                   :opacity="0.65"
                   rounded="sm">
          <b-form-group>
            <h6 class="section-label mb-1">
              {{ $t('variable.valueType') }}
            </h6>
            <v-select v-model="valueType"
                      :label="`name`"
                      :options="variableValueTypes"/>
            <h6 class="section-label mt-2">
              {{ valueType != null ? valueType.displayText : `` }}
            </h6>
          </b-form-group>
        </b-overlay>
      </b-col>


      <div v-if="type !== null && type.name === `ORG_BANK` && valueType !== null && valueType.name === `SELECTION`">
        <b-col v-if="linkedVariables.length !== 0">
          <br/>
          <template v-for="(lv,ix) in linkedVariables">
            <div :key="ix">
              <b-row>
                <b-col cols="10">
                  <b-row>
                    <b-col cols="6" class="scroll text-wrap center">
                      {{ name }}-
                    </b-col>
                    <b-col cols="6">
                      <b-form-input v-model="lv.name"
                                    id="var-name"
                                    :placeholder="$t('variable.subvarible')"
                                    v-no-space/>
                    </b-col>
                  </b-row>
                  <b-row class="mr-0 ml-0 mt-1 mb-1">
                    <b-form-input v-model="lv.description"
                                  id="var-description"
                                  :placeholder="$t('variable.description')"/>
                  </b-row>
                </b-col>

                <b-col cols="1">
                  <b-button v-b-tooltip.hover.bottom="$t('variable.removeOption')"
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-danger"
                            class="rounded-circle m-1"
                            @click="removeLinkedVariable(ix)">
                    <div>
                      <feather-icon size="18" icon="TrashIcon"/>
                    </div>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </template>
        </b-col>
        <b-col>
          <br/>
          <b-button @click="addLinkedVariable">{{ $t('variable.addLinkedVariable')}}</b-button>
        </b-col>
      </div>

      <b-col v-if="type !== null && type.name === `ORG_BANK` && valueType !== null && valueType.name === `SELECTION`">
        <br/>
        <b-row>
          <b-col cols="10">
            <b-row>
              <b-col v-for="(linkedVar, i) in linkedVariables" :key="i">
                <div
                    :style="linkedVar.selected && linkedVar.options.length !== 0
                    ? `box-shadow: 0 0 1px 1px #03A5AA, 0 0 1px 1px #03A5AA; border-radius: 5px; padding: 5px`
                    : `border-radius: 5px; padding: 5px`">
                  <b-row v-if="linkedVar.options.length !== 0">
                    <b-col class="text-center">
                      <b-form-checkbox @change="updatedHeaderVariable($event, linkedVar.tempId)"
                                       v-model="linkedVar.selected"
                                       plain>
                        {{ $t('variable.assignAsTitle')}}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row v-for="(option,ix) in linkedVar.options" :key="ix">
                    <b-form-input class="m-1"
                                  v-model="option.option"
                                  id="var-option"
                                  :placeholder="name + '-' +linkedVar.name"/>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="2">
            <b-row>
              <b-col v-for="(linkedVar, i) in linkedVariables" :key="i">
                <b-row v-for="(option,ix) in linkedVar.options" :key="ix">
                  <b-button v-if="i === 0"
                            v-b-tooltip.hover.bottom="$t('variable.removeOption')"
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-danger"
                            class="rounded-circle m-1"
                            @click="removeOptionFromLinkedVariable(option, ix)">
                    <div>
                      <feather-icon size="18" icon="TrashIcon"/>
                    </div>
                  </b-button>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-button @click="addVariableValueOptionForLinkedVariable">
          {{ $t('variable.addOption') }}
        </b-button>

      </b-col>

      <b-col v-if="type !== null && type.name !== `ORG_BANK` && valueType !== null && valueType.name === `SELECTION`">
        <br/>
        <template v-for="(option, ix) in options">
          <b-row :key="ix" class="mt-1 mb-1">
            <b-col cols="">
              <b-form-input v-model="option.option"
                            id="var-option"
                            :placeholder="$t('variable.variableValueOption')"/>
            </b-col>
            <b-col cols="2">
              <b-button v-b-tooltip.hover.bottom="$t('variable.removeOption')"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-danger"
                        class="rounded-circle ml-1 mr-1"
                        @click="removeOption(option)">
                <div class="icon-wrapper">
                  <feather-icon icon="TrashIcon"/>
                </div>
              </b-button>
            </b-col>
          </b-row>
        </template>
        <b-button @click="addVariableValueOption">
          {{ $t('variable.addOption') }}
        </b-button>

      </b-col>

      <b-col>
        <br/>
        <div class="d-flex justify-content-between align-items-center">
          <label for="var-is-required"> {{ $t('variable.isRequired') }}</label>
          <b-form-checkbox v-model="isRequired"
                           id="var-is-required"
                           switch/>
        </div>
      </b-col>
      <b-col>
        <br/>
        <div class="d-flex justify-content-between align-items-center">
          <label for="var-is-public"> {{ $t('variable.isPublic') }}</label>
          <b-form-checkbox v-model="isPublic"
                           id="var-is-public"
                           switch/>
        </div>
      </b-col>

      <b-col cols="12">
        <br/>
        <b-row align-h="end" class="px-1">
          <b-button @click="addVariable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    :disabled="invalid"
                    variant="primary">
            {{ $t('buttons.save') }}
          </b-button>
        </b-row>
      </b-col>
    </b-form>
  </div>
</template>

<script>
import {BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BOverlay, BRow, VBTooltip} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import {PEventBus, VariableEventTypes} from "@/services/PEventBus";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {v4 as uuidv4} from 'uuid'
import i18n from "@/libs/i18n";

export default {
  name: "AddNewVariable",
  components: {
    BRow, BCol, BButton, BForm, BFormInput, BFormCheckbox, BFormGroup, vSelect, BOverlay
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      linkedVariables: [],
      linkedOptionCount: 0,

      invalid: false,

      name: null,
      description: null,
      type: null,
      valueType: null,
      isRequired: false,
      isPublic: false,

      options: []
    }
  },
  created() {
    this.fetchVariableTypes()
    this.fetchVariableValueTypes()
  },
  computed: {
    ...mapState({
      variables: state => state.variables.variableList,
      variableTypes: state => state.variables.variableTypes,
      variableValueTypes: state => state.variables.variableValueTypes
    })
  },
  mounted() {
    PEventBus.$on(VariableEventTypes.VARIABLE_CREATED, () => this.showSuccessToast())
    PEventBus.$on(VariableEventTypes.VARIABLE_CREATION_FAILED, () => this.showErrorToast())
  },
  beforeDestroy() {
    PEventBus.$off(VariableEventTypes.VARIABLE_CREATED, () => this.showSuccessToast())
    PEventBus.$off(VariableEventTypes.VARIABLE_CREATION_FAILED, () => this.showErrorToast())
  },
  methods: {
    fetchVariables() {
      this.$store.dispatch("variables/loadAll")
    },
    fetchVariableTypes() {
      this.$store.dispatch("variables/getTypes")
    },
    fetchVariableValueTypes() {
      this.$store.dispatch("variables/getValueTypes")
    },
    addVariable() {
      if (this.type.name === `ORG_BANK` && this.valueType.name === `SELECTION`) {
        let childVariables = this.linkedVariables
            .filter(lv => !lv.selected)
            .map(lv => {
              return {
                name: this.name + '-' + lv.name,
                description: lv.description,
                type: this.type.name,
                valueType: this.valueType.name,
                isRequired: this.isRequired,
                isPublic: this.isPublic,
                options: lv.options.map(o => o.option)
              }
            });

        let parentVariableOptionsReference = this.linkedVariables
            .filter(lv => lv.selected)[0];

        let parentVariable = {
          name: this.name,
          description: this.description,
          type: this.type.name,
          valueType: this.valueType.name,
          isRequired: this.isRequired,
          isPublic: this.isPublic,
          options: parentVariableOptionsReference.options.map(o => o.option)
        }


        let payload = {
          parent: parentVariable,
          children: childVariables
        }

        this.$store.dispatch("variables/addLinkedVariable", payload)
      } else {
        const options = this.options.map(o => o.option)

        const payload = {
          name: this.name,
          description: this.description,
          type: this.type.name,
          valueType: this.valueType.name,
          isRequired: this.isRequired,
          isPublic: this.isPublic,
          options: options.length > 0 ? options : []
        }

        this.$store.dispatch("variables/addVariable", payload)
      }

    },
    showSuccessToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.variable.added'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    showErrorToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.variable.failedOnAdd'),
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      })
    },
    addVariableValueOptionForLinkedVariable() {
      this.linkedOptionCount++
      this.linkedVariables.map(lv => {
        lv.options.push({
          id: uuidv4(),
          option: null
        })
      })
    },
    addVariableValueOption() {
      this.options.push({
        id: uuidv4(),
        option: null
      })
    },
    removeOption(option) {
      let removedElement = {}
      if (option.optionId === null) {
        removedElement = this.options.filter(o => o.optionId === option.optionId)[0]
      } else {
        removedElement = this.options.filter(o => o.id === option.id)[0]
      }

      let removedIndex = this.options.indexOf(removedElement)
      this.options.splice(removedIndex, 1)
    },
    removeOptionFromLinkedVariable(option, index) {
      this.linkedVariables.map(lv => {
        lv.options.splice(index, 1)
      })
    },
    addLinkedVariable() {
      let firstVariable = this.linkedVariables.length === 0;

      this.linkedVariables.push({
        tempId: uuidv4(),
        name: null,
        description: null,
        options: [],
        selected: firstVariable
      })

      this.linkedVariables.map(lv => {
        for (let i = lv.options.length; i < this.linkedOptionCount; i++) {
          lv.options.push({
            id: uuidv4(),
            option: null
          })
        }
      })
    },
    removeLinkedVariable(ix) {
      this.linkedVariables.splice(ix, 1)
    },
    updatedHeaderVariable(event, tempId) {
      if (event) {
        this.linkedVariables.map(lv => {
          lv.selected = lv.tempId === tempId;
        })
      }
    }
  }
}
</script>

<style scoped>

</style>